var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "w": "100%",
      "background-color": "white",
      "border-radius": "12px",
      "border": "1px solid #f2f2f2",
      "box-shadow": "0px 5px 30px 0px #0000000D",
      "px": ['12px', '24px'],
      "py": ['12px', '24px'],
      "pos": "relative",
      "my": ['15px', '30px'],
      "flex-dir": ['column', 'row']
    }
  }, [_c('c-box', {
    attrs: {
      "mr": ['0', '30px']
    }
  }, [_c('c-box', {
    attrs: {
      "d": "block",
      "w": ['100%', '150px'],
      "h": ['150px', '100%']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "flex-direction": "column",
      "gap": ['6px', '10px']
    }
  }, _vm._l(3, function (index) {
    return _c('c-box', {
      key: index
    }, [_c('c-box', {
      attrs: {
        "d": "block",
        "w": "150px",
        "h": ['16px', '18px'],
        "mb": "12px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1), _c('c-box', {
      attrs: {
        "d": "block",
        "w": "200px",
        "h": ['14px', '16px'],
        "mb": "12px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1)], 1);
  }), 1), _c('c-flex', {
    attrs: {
      "flex-shrink": "0",
      "flex-direction": "column",
      "justify-content": "space-between",
      "align-items": "flex-end"
    }
  }, [_c('c-box', {
    attrs: {
      "d": "block",
      "w": "65px",
      "h": ['30px', '35px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": "block",
      "w": "120px",
      "h": ['16px', '18px']
    }
  }, [_c('vue-skeleton-loader', {
    attrs: {
      "type": "rect",
      "width": "100%",
      "height": "100%",
      "rounded": "",
      "animation": "fade"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }