<template>
  <CBreadcrumb
    v-bind="$attrs"
    :add-separator="false"
    separator="›"
    :d="['none', 'block']"
    mt="1rem"
    font-family="Roboto"
  >
    <CBreadcrumbItem
      v-for="(path, index) in paths"
      :key="path.label"
      :is-current-page="path.isCurrent"
    >
      <CBreadcrumbLink
        as="router-link"
        :to="parsePath(path.href)"
        :color="path.isCurrent ? '#333' : '#008C81'"
      >
        {{ path.label }}
      </CBreadcrumbLink>
      <CBreadcrumbSeparator
        v-if="index !== paths.length - 1"
        px="35px"
        color="#008C81"
        font-size="16px"
      />
    </CBreadcrumbItem>
  </CBreadcrumb>
</template>

<script>
import {
  CBreadcrumb,
  CBreadcrumbLink,
  CBreadcrumbItem,
  CBreadcrumbSeparator,
} from '@chakra-ui/vue'

export default {
  components: {
    CBreadcrumb,
    CBreadcrumbLink,
    CBreadcrumbItem,
    CBreadcrumbSeparator,
  },
  props: {
    paths: {
      type: Array,
      default: () => [
        {
          label: 'this',
          href: '/',
        },
        {
          label: 'is',
          href: { name: 'client.index' },
        },
        {
          label: 'an',
          href: '/',
        },
        {
          label: 'example',
          isCurrent: true,
        },
      ],
    },
  },
  methods: {
    parsePath(path) {
      switch (typeof path) {
        case 'string':
          return path
        case 'object':
          return this.$router.resolve(path)?.route?.fullPath || '#'
        default:
          return '#'
      }
    },
  },
}
</script>
