var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-grid', {
    attrs: {
      "template-columns": ['repeat(1, 1fr)', 'repeat(2, 1fr)'],
      "gap": "6"
    }
  }, _vm._l(2, function (index) {
    return _c('c-box', {
      key: index,
      attrs: {
        "w": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%"
      }
    }, [_c('c-flex', {
      attrs: {
        "w": "100%",
        "background-color": "white",
        "border-radius": "12px",
        "border": "1px solid #f2f2f2",
        "box-shadow": "0px 5px 30px 0px #0000000D",
        "px": "16px",
        "py": "16px",
        "pos": "relative",
        "margi-bottom": "16px",
        "cursor": "pointer"
      }
    }, [_c('c-box', {
      attrs: {
        "w": "100%"
      }
    }, [_c('c-box', {
      attrs: {
        "d": "block",
        "w": "170px",
        "h": "22px",
        "mb": "10px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1), _c('c-box', {
      attrs: {
        "d": "block",
        "w": "100px",
        "h": "14px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1)], 1), _c('c-box', {
      attrs: {
        "d": "block",
        "w": "20px",
        "h": "32px",
        "border-radius": "20px",
        "align-self": "center"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "rounded": "",
        "animation": "fade"
      }
    })], 1)], 1)], 1)], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }