<template>
  <c-grid
    :template-columns="['repeat(1, 1fr)', 'repeat(2, 1fr)']"
    gap="6"
  >
    <c-box
      v-for="index in 2"
      :key="index"
      w="100%"
    >
      <c-box w="100%">
        <c-flex
          w="100%"
          background-color="white"
          border-radius="12px"
          border="1px solid #f2f2f2"
          box-shadow="0px 5px 30px 0px #0000000D"
          px="16px"
          py="16px"
          pos="relative"
          margi-bottom="16px"
          cursor="pointer"
        >
          <c-box w="100%">
            <c-box
              d="block"
              w="170px"
              h="22px"
              mb="10px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
            <c-box
              d="block"
              w="100px"
              h="14px"
            >
              <vue-skeleton-loader
                type="rect"
                width="100%"
                height="100%"
                rounded
                animation="fade"
              />
            </c-box>
          </c-box>
          <c-box
            d="block"
            w="20px"
            h="32px"
            border-radius="20px"
            align-self="center"
          >
            <vue-skeleton-loader
              type="rect"
              width="100%"
              height="100%"
              rounded
              animation="fade"
            />
          </c-box>
        </c-flex>
      </c-box>
    </c-box>
  </c-grid>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'

export default {
  name: 'SkeletonCardQuestionnaire',
  components: {
    VueSkeletonLoader,
  },
}
</script>
