<template>
  <c-flex
    w="100%"
    background-color="white"
    border-radius="12px"
    border="1px solid #f2f2f2"
    box-shadow="0px 5px 30px 0px #0000000D"
    :px="['12px', '24px']"
    :py="['12px', '24px']"
    pos="relative"
    :my="['15px', '30px']"
    :flex-dir="['column', 'row']"
  >
    <c-box :mr="['0', '30px']">
      <c-box
        d="block"
        :w="['100%', '150px']"
        :h="['150px', '100%']"
      >
        <vue-skeleton-loader
          type="rect"
          width="100%"
          height="100%"
          rounded
          animation="fade"
        />
      </c-box>
    </c-box>
    <c-flex
      flex-grow="1"
      flex-direction="column"
      :gap="['6px', '10px']"
    >
      <c-box
        v-for="index in 3"
        :key="index"
      >
        <c-box
          d="block"
          w="150px"
          :h="['16px', '18px']"
          mb="12px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
        <c-box
          d="block"
          w="200px"
          :h="['14px', '16px']"
          mb="12px"
        >
          <vue-skeleton-loader
            type="rect"
            width="100%"
            height="100%"
            rounded
            animation="fade"
          />
        </c-box>
      </c-box>
    </c-flex>
    <c-flex
      flex-shrink="0"
      flex-direction="column"
      justify-content="space-between"
      align-items="flex-end"
    >
      <c-box
        d="block"
        w="65px"
        :h="['30px', '35px']"
      >
        <vue-skeleton-loader
          type="rect"
          width="100%"
          height="100%"
          rounded
          animation="fade"
        />
      </c-box>
      <c-box
        d="block"
        w="120px"
        :h="['16px', '18px']"
      >
        <vue-skeleton-loader
          type="rect"
          width="100%"
          height="100%"
          rounded
          animation="fade"
        />
      </c-box>
    </c-flex>
  </c-flex>
</template>

<script>
import VueSkeletonLoader from 'skeleton-loader-vue'  

export default {
  name: 'SkeletonProgramActive',
  components: {
    VueSkeletonLoader,
  }, 
}
</script>
