var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CBreadcrumb', _vm._b({
    attrs: {
      "add-separator": false,
      "separator": "›",
      "d": ['none', 'block'],
      "mt": "1rem",
      "font-family": "Roboto"
    }
  }, 'CBreadcrumb', _vm.$attrs, false), _vm._l(_vm.paths, function (path, index) {
    return _c('CBreadcrumbItem', {
      key: path.label,
      attrs: {
        "is-current-page": path.isCurrent
      }
    }, [_c('CBreadcrumbLink', {
      attrs: {
        "as": "router-link",
        "to": _vm.parsePath(path.href),
        "color": path.isCurrent ? '#333' : '#008C81'
      }
    }, [_vm._v(" " + _vm._s(path.label) + " ")]), index !== _vm.paths.length - 1 ? _c('CBreadcrumbSeparator', {
      attrs: {
        "px": "35px",
        "color": "#008C81",
        "font-size": "16px"
      }
    }) : _vm._e()], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }